.change-plan-container {
  height: 100%;
  padding: 24px;
  .page-header {
    flex-wrap: wrap;
  }
  .plan-type-menu {
    margin: 0;
  }

  .list-container {
    overflow: scroll;
    .MuiFormControlLabel-label {
      margin-left: 10px;
    }
  }
}
.message-container{
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 640px) {
  .change-plan-container {
    padding: 0;
    .list-container {
      height: calc(100% - 108px);
    }
    .mobile-list-container {
      height: calc(100% - 190px);
    }
    .plan-grid {
      margin-top: 30px;
    }
  }
}
