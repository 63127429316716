.back-button {
    height: 40px;

&.MuiButton-root {
    padding: 0;
    font-size: $body;
    text-transform: none;
    white-space: nowrap;
    color: $white-color;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    min-height: 40px;
    min-width: 40px;
    border-radius: 50%;
    }
    .material-icons-outlined {
        font-size: $x-large;
        font-weight: 500;
    }
}

@media (max-width: 820px){
    .back-button.MuiButtonBase-root {
        height: 30px;
        width: 30px;
        min-height: 30px;
        min-width: 30px;
    
    .material-icons-outlined {
        font-size: $title;
    }
    }
}