.terms-and-condition-block {
  margin-top: 20px;
}
.terms-condition-file-name {
  padding-left: 15px;
  font-size: $subTitle;
  color: $active-color;
}
.T-and-C-close-icon {
  border-radius: 50%;
  background-color: $primary-color;
  color: $white-color;
  font-size: 1rem;
  margin-left: 10px;
  vertical-align: middle;
}
.multi-level-dropdown {
  > .MuiPaper-root {
    color: #000;
    box-shadow: none;
    &::before {
      display: none;
    }
    .MuiAccordionSummary-content {
      .MuiTypography-root {
        font-size: $placeholder;
      }
    }
    .MuiAccordionSummary-expandIconWrapper {
      color: $dark-grey-color;
    }
    .MuiCollapse-root {
      .MuiAccordionDetails-root {
        padding: 0 24px;
        display: flex;
        flex-direction: column;
        gap:16px;
      }
      .MuiTypography-root {
        font-size: $placeholder !important;
        
      }
    }
  }
}

@media (max-width: 820px) {
  .terms-and-condition-block {
    margin-top: 16px;
  }
  .terms-condition-file-name {
    font-size: $placeholder;
    padding-left: 8px;
  }
}
