.list-scroll {
  overflow-y: auto;
  margin-right: -24px;
  padding: 1px 24px 1px 1px;
}
.recipe-container {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: 1fr 1fr;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  background-color: $white-color;
  margin-bottom: 14px;
  height: 138px;
}

.recipe-image-container {
  grid-row: 1/3;
  img {
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
    border-radius: 8px 0 0 8px;
  }
}

.recipe-name-container {
  display: flex;
  justify-content: space-between;
  padding: 14px 14px 0 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  :first-child {
    font-size: $title;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    height: fit-content;
  }
  .date-container {
    color: $primary-color;
    white-space: nowrap;
  }
}

.recipe-detail-container {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(4, 1fr);
  padding: 0 14px 14px 14px;
  align-items: flex-end;
  .detail-label {
    color: $dark-grey-color;
    + div {
      font-size: $body;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  > :last-child {
    padding: 2px;
    white-space: nowrap;
  }
}

.id-span {
  font-size: $subTitle;
}

.mobile-view-date-container {
  display: none;
}

@media (max-width: 820px) {
  .recipe-container {
    grid-template-columns: 1fr;
    grid-template-rows: minmax(194px, 1fr);
    position: relative;
    height: auto;
    img {
      height: 194px;
      border-radius: 8px 8px 0 0;
    }
  }
  .recipe-image-container {
    grid-row: 1/2;
  }
  .recipe-name-container {
    :first-child {
      font-size: $body;
    }
    .id-span {
      position: absolute;
      top: 154px;
      right: 8px;
      background-color: $white-color;
      opacity: 0.5;
      border-radius: 4px;
      padding: 4px 12px;
      color: $primary-color;
      white-space: nowrap;
    }
  }
  .mobile-view-date-container{
    display: block;
  }
  .recipe-detail-container {
    grid-template-columns: repeat(1, 100%);
    gap: 14px;
    padding: 10px 14px 14px;
    :last-child {
      padding: 0;
      white-space: nowrap;
      justify-content: flex-start;
    }
  }
  .date-container {
    display: none;
  }
}
