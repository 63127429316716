.view-page-container {
  .account-page-title {
    @media screen and (max-width: 1024px) {
      font-size: $title;
    }
  }
  .main-details-container {
    @media screen and (max-width: 820px) {
      margin-top: 24px;
    }
  }
  .accounting-detail-block {
    width: 550px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 24px;
    align-items: center;
    margin-bottom: 16px;
    @media screen and (max-width: 600px) {
      margin-left: 4px;
      width: 100%;
      margin-bottom: 12px;
    }
    .software-image {
      &:first-child {
        display: inherit;
      }
    }
    .button {
      @media screen and (max-width: 820px) {
        height: 36px;
        font-size: $placeholder;
      }
    }
  }
}
.credential-modal-body{
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow-y: scroll;
  padding: 4px 24px;
}
