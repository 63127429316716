.register-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  padding: 70px 10%;

  > div:not(.auth-company-logo):not(.modal) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 63rem;
  }
}

.register-select {
  width: 100%;
}

.company-logo {
  position: fixed;
  top: 40px;
  left: 40px;
}

.register-button-title-row {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
}

.register-back-button-container {
  position: absolute;
  top: calc(50% - 20px);
  left: 0;
}

.register-title {
  font-size: $xx-large;
  font-weight: 500;
}

.register-subtitle {
  margin-top: 10px;
  font-size: $body;
  color: $grey-color;
  text-align: center;
}

.register-step-row {
  display: flex;
  justify-content: space-between;
  gap: 32px;
  font-size: $body;
  color: $grey-color;
  margin: 80px 0;

  > div {
    display: flex;
    align-items: center;
  }

  .register-step {
    display: flex;
    align-items: center;
  }

  .register-completed-step {
    color: $primary-color;

    .register-step-number {
      color: $white-color;
      background-color: $primary-color;
      border-color: $primary-color;
    }
  }

  .register-step-number {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    min-height: 30px;
    min-width: 30px;
    font-size: $placeholder;
    color: $grey-color;
    line-height: 1;
    border-radius: 50%;
    border: 1px solid $grey-color;
  }

  .register-step-name {
    margin-left: 16px;
    white-space: nowrap;
  }
}

.register-fields-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px 32px;
  width: 100%;
  max-width: 63rem;

  .input,
  .select {
    width: 100%;

    .material-icons-outlined {
      color: $placeholder-color;
      cursor: pointer;
    }
  }
}
.search-icon {
  cursor: pointer;
  margin: 10px;
}

.register-footer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.register-button {
  height: 48px;
  width: 100%;
  max-width: 488px;

  &.MuiButtonBase-root {
    margin-top: 48px;
    height: 48px;
  }
}

.register-checkbox {
  display: flex;
  margin-top: 16px;
  font-size: $placeholder;
  color: $grey-color;

  > div {
    margin-top: -2px;
  }

  .MuiFormControlLabel-root {
    align-items: flex-start;
    margin-left: 0;
    margin-right: 0;
  }
}

.checkbox-legal-policies {
  margin-left: 5px;
  font-weight: 500;
  cursor: pointer;
  a {
    color: $dark-blue-color;
    text-decoration: none;
  }
}

.register-modal {
  max-width: 794px;
  width: fit-content;
  max-height: 80vh;
}

.table-row {
  border: none;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
}
.modal-body.company-details-table {
  padding: 0 24px 24px;
  max-height: 65vh;
  overflow-y: scroll;
  overflow-x: hidden;
  .list-container {
    margin-top: 0;
    th.MuiTableCell-stickyHeader {
      background-color: $white-color;
    }
  }
}

.sign-in-link {
  cursor: pointer;
  font-weight: 500;
}

.modal-table {
  max-width: 80vw;
  > div {
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

@media screen and (max-width: 1024px) {
  .register-container > div:not(.auth-company-logo):not(.modal) {
    max-width: 45.5rem;
  }
}

@media (max-width: 820px) {
  .register-title {
    font-size: $body;
  }

  .register-back-button-container {
    top: -2px;
  }

  .register-button-title-row {
    margin-top: 76px;
    font-size: $placeholder;
  }

  .register-container {
    padding: 0 24px 32px 24px;

    > div:not(.auth-company-logo):not(.modal) {
      margin-top: 0;
    }
  }

  .register-fields-grid {
    gap: 16px;
    grid-template-columns: 1fr;
  }

  .register-step-row {
    gap: 6px;
    margin: 32px 0;
    font-size: $placeholder;

    .register-step-name {
      margin-left: 5px;
    }

    .register-step-number {
      height: 22px;
      width: 22px;
      min-height: 22px;
      min-width: 22px;
      font-size: $caption;
    }
  }

  .register-checkbox {
    width: 100%;
  }

  .register-subtitle {
    margin-top: 8px;
    font-size: $placeholder;
  }
  .form-full-width-field {
    grid-column: unset;
  }

  .register-button.MuiButtonBase-root {
    margin-top: 32px;
    height: 48px;
  }

  .checkbox-legal-policies {
    margin-top: 20px;
  }
}
.disable-title{
  color: $active-color;
}

@media screen and (max-width: 640px) {
  .register-button-title-row {
    font-size: $caption;
  }

  .register-step-row {
    font-size: $caption;
  }
}

@media screen and (max-width: 360px) {
  .register-step-row {
    font-size: $foot-note;
    .register-step-number {
      height: 20px;
      width: 20px;
      min-height: 20px;
      min-width: 20px;
    }
  }
}
