.create-order-form {
  padding-bottom: 24px;
}
.create-order-form-container {
  padding: 0px;
  .form-fields-grid {
    padding: 24px;
  }
  .form-button-row {
    padding: 0 24px 24px 24px;
    position: fixed;
    bottom: 0;
    width: 100%;
    background: $white-color;
    box-shadow: 3px -1px 4px rgba(0, 0, 0, 0.16);
    padding-top: 24px;
  }

  .select-product-container {
    padding: 24px 24px 0 24px;
    .select-product {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 11px;
      .order-details {
        .order-list {
          padding: 5px;
        }
        .order-list-accordion {
          display: none;
        }
        .order-details-title {
          font-weight: 400;
          font-size: $body;
          color: $primary-color;
          margin: 6px 0 30px 0;
        }
      }
      .order-view {
        border: 1px solid $primary-color;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
        border-radius: 8px;
        margin-bottom: 12px;
        &:last-child {
          margin-bottom: 0;
        }
        .customize-order {
          display: grid;
          grid-template-columns: auto auto;
          justify-content: space-between;
          align-items: center;
          padding: 16px;
          gap: 14px;
          .order-name {
            font-weight: 400;
            font-size: $subTitle;
            color: $dark-grey-color;
            margin: 0;
            word-break: break-word;
            line-height: 24px;
            .order-id {
              color: $primary-color;
            }
            .checkbox {
              height: 24px;
              width: 24px;
              border-radius: 5px;
            }
            .checked-checkbox {
              height: 24px;
              width: 24px;
              &::before {
                left: 8px;
                top: 3px;
                width: 4px;
                height: 12px;
              }
            }
          }

          .unit-quantity-container {
            display: flex;
            flex-direction: row;
            gap: 14px;
            align-items: center;
          }
        }
        .additional-actions {
          display: flex;
          justify-content: space-between;
          padding: 16px;
          gap: 14px;
          .additional-note {
            font-weight: 400;
            font-size: $caption;
            color: $active-color;
            width: 259px;
            margin: 0;
            word-break: break-word;
            line-height: 16px;
          }
        }
      }
      .add-product {
        border: 1px solid transparent;
        .customize-order {
          padding: 20px 16px;
          .order-name {
            display: flex;
            align-items: flex-start;
          }
        }
      }
      .select-units {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .unit-container {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
          .selected-chip {
            color: $white-color;
            background-color: $primary-color;
          }
          .unSelected-chip {
            color: $primary-color;
            background-color: $secondary-background-color;
          }
        }
      }
    }
  }
  .action-notes {
    color: $white-color;
    background-color:$primary-color;
    font-weight: 500;
    font-size: $body;
    display: flex;
    align-items: center;
    > svg {
      height: 38px;
      width: 38px;
      padding: 6px;
      border: 1px solid $primary-color;
      border-radius: 4px;

      &:hover {
        box-shadow: 0px 0px 4px $primary-hover-color;
      }
    }
  }
  .no-items {
    font-weight: 400;
    font-size: $title;
    line-height: 48px;
    width: 354px;
    color: $dark-grey-color;
    text-align: center;
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
  }
}
.primary-divider {
  height: 2px;
  background: $main-bg-color;
}
.secondary-divider {
  height: 1px;
  background: $light-background-color;
}
.tab-index {
  overflow: hidden;
}

.accordion {
  background: $main-bg-color;
  border: 1px solid $gray91-color;
  border-radius: 8px !important;
  box-shadow: none;
  .accordion-summary {
    min-height: 52px;
    .MuiAccordionSummary-content {
      font-weight: 500;
      font-size: $body;
      color: $primary-color;
      margin: 0;
    }
    .MuiSvgIcon-root {
      width: 34px;
      height: 34px;
      fill: $primary-color;
    }
  }
  .MuiCollapse-root {
    background: #fff;
    border-radius: 0 0 8px 8px;
    max-height: 500px;
    overflow-y: scroll;
    .MuiAccordionDetails-root {
      padding: 0;
      .order-view {
        border: none;
        box-shadow: none;
        border-radius: 0;
        border-bottom: 1px solid #e8e8e8;
        :last-child {
          border-bottom: none;
        }
        .secondary-divider {
          margin: 0 16px;
        }
      }
    }
  }
}

.note-container {
  margin-bottom: 90px;
  padding: 0 24px;
}

.disable {
  background-color: rgba(0, 0, 0, 0.12) !important;
  color: $dark-grey-color;
}

@media screen and (max-width: 1279px) {
  .create-order-form-container {
    .form-button-row {
      left: 0;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
      padding-left: 48px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .select-product {
    .order-view {
      .customize-order {
        padding: 14px;
        .select-units {
          justify-content: flex-start !important;
        }
      }
    }
  }
}

@media screen and (max-width: 820px) {
  .create-order-form {
    padding-bottom: 0;
  }
  .note-container {
    padding: 0;
  }
}

@media screen and (max-width: 768px) {
  .select-product {
    grid-template-columns: auto !important;
    gap: 16px !important;
    .order-details {
      order: 2;
      margin-bottom: 90px;
      .order-details-title {
        display: none;
      }
      .order-list {
        display: none;
      }
      .order-list-accordion {
        display: block !important;
        .order-view {
          .customize-order {
            padding: 14px;
            .select-units {
              justify-content: flex-end !important;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .create-order-form-container {
    padding: 0px;
    .form-fields-grid {
      padding: 0 0 16px 0;
    }
    .form-button-row {
      padding: 24px;
    }
    .select-product-container {
      padding: 16px 0 0 0;
      .select-product {
        .order-details {
          .order-details-title {
            font-weight: 500;
            margin: 0 0 16px 0;
          }
        }
        .order-view {
          .customize-order {
            flex-direction: column;
            align-items: flex-start;
            padding: 12px 12px 14px;
            .order-name {
              font-size: $placeholder;
              .checkbox {
                height: 20px;
                width: 20px;
                border-radius: 5px;
              }
              .checked-checkbox {
                height: 20px;
                width: 20px;
                &::before {
                  left: 6px;
                  top: 2px;
                  width: 3px;
                  height: 10px;
                }
              }
            }
            .select-units {
              justify-content: flex-start !important;
            }
          }
          .secondary-divider {
            margin: 0 12px;
          }
          .additional-actions {
            padding: 14px 12px 12px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .create-order-form-container {
    .select-product-container {
      .customize-order {
        flex-direction: column;
        align-items: flex-start;
        .select-units {
          justify-content: flex-start !important;
        }
      }
      .additional-actions {
        .additional-note {
          width: auto !important;
        }
      }
    }
    .no-items {
      font-size: $body;
      line-height: 35px;
      padding: 35px;
      width: auto;
    }
  }
}
@media screen and (max-width: 384px) {
  .select-product {
    .order-details {
      margin-bottom: 130px;
    }
  }
}
@media screen and (max-width: 320px) {
  .select-product-container {
    .autocomplete {
      .input {
        .MuiFilledInput-root {
          width: auto !important;
        }
      }
    }
  }

  .additional-note {
    width: auto !important;
  }
}
