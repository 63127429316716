.white-card {
  padding: 24px 0;
}
.expense-list-container{
  height: calc(100% - 16px);
}
.expense-list {
  height: calc(100% - 241px);
}
.expense-status-container {
  padding-left: 10px !important;
  .MuiSwitch-colorPrimary {
    color: $white-color;
  }
  .MuiSwitch-colorPrimary.Mui-disabled {
    color: $white-color;
  }
  .MuiSwitch-root {
    height: 16px;
    position: relative;
    width: 35px;
    padding: 0;
    .MuiSwitch-switchBase.Mui-disabled {
      ~ .MuiSwitch-track {
        background-color: $gray91-color;
      }
    }
    .MuiSwitch-switchBase.Mui-checked {
      ~ .MuiSwitch-track {
        background-color: $primary-color;
      }
    }
    .MuiSwitch-switchBase.Mui-checked.Mui-disabled {
      ~ .MuiSwitch-track {
        background-color: #f21d8657;
      }
    }
    .MuiSwitch-switchBase {
      position: absolute;
      top: 0;
      padding: 2px 1px;
    }
    .MuiSwitch-track {
      display: flex;
      background-color: $dark-grey-color;
      opacity: 1;
      width: 30px;
      height: 16px;
    }
    .MuiSwitch-thumb {
      width: 12px;
      height: 12px;
    }
  }
}

@media (max-width: 820px) {
  .white-card {
    padding: 16px 0;
  }
  .expense-list {
    // height: calc(100% - 431px);
    overflow: scroll;
    // height:unset;
  }
  .expense-list-container{
    overflow-y: scroll;
    height: calc(100% - 37px);
    padding-left: 4px;
    padding-right: 24px;
    margin-right: -24px;
  }
}
