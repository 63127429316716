.loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 300px;
    height: 100%;
    width: 100%;
    
    img {
        height: 60px;
    }
}