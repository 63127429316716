.reconcile-list-container {
  padding-top: 24px;
  height: calc(100% - 40px);
  display: grid;
  grid-template-rows: max-content auto;
  .list-container {
    overflow: scroll;
  }
}

.reconcile-white-block-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}
.reconcile-white-block-row > div {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1 1;
  gap: 8px;
  position: relative;
}

.reconcile-white-block-row > .white-block {
  min-width: 182px;
  background-color: #ffffff;
  box-shadow: 0px 0px 4px #00000029;
  -webkit-box-shadow: 0px 0px 4px #00000029;
  border-radius: 8px;
  padding: 24px 0;

  .subtitle-text {
    font-weight: 500;
    color: #666666;
  }
  .main-text {
    color: $primary-color;
    font-weight: 600;
    font-size: $xx-large;
  }
  .main-text-primary {
    color: $primary-color;
    font-weight: 600;
    font-size: $large;
    background-color: #fff5fa;
    padding: 0 12px;
  }
}

.reconcile-total-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 68px;
  margin-top: 0;
  @extend .invoices-total-container;
}
.adjustment-detail-container {
  display: grid;
  grid-template: auto auto/1fr 1fr;
  padding: 14px;
  gap: 16px;
  :first-child {
    grid-column: 1/4;
  }
}

.notes-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow-y: scroll;
  height: 212px;
  padding: 4px;
}
.button-margin {
  margin-top: 24px;
}
.reconcile-pagination {
  padding-top: 0;
}

@media screen and (max-width: 820px) {
  .reconcile-list-container {
    .list-container {
      overflow: unset;
      display: flex;
      height: auto;
    }
    .no-record-text {
      min-height: 230px;
    }
    .reconcile-white-block-row {
      :first-child {
        grid-area: 1 / 1 / 2 / 3;
      }
      > div {
        min-width: 100%;
        .main-text {
          font-size: $title;
        }
      }
    }

    .reconcile-white-block-row {
      grid-template: auto auto/auto auto;
      .main-text-primary {
        font-size: $title;
      }
      .white-block {
        padding: 16px 0;
      }
    }
    .subtitle-text {
      font-size: $caption;
    }
  }
  .reconcile-total-container {
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;
    gap: 12px;
  }
  .reconcile-note {
    overflow: visible;
    white-space: normal;
    word-break: break-word !important;
  }
  .adjustment-detail-container {
    padding: 14px;
    grid-template: auto auto auto/1fr;
    :first-child {
      grid-column: 1/2;
    }
  }
}
@media only Print {
  .notes-container{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    margin-top: 8px;
    overflow: visible;
    height: auto;
  }
  .total-container {
    margin: 0 5px;
    margin-top: 8px;
    flex: 0 0 50%;
    background-color: $gray91-color;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    height: fit-content;

    .sub-total-container {
      padding: 14px;
      display: flex;
      flex-direction: column;
      gap: 14px;
      background-color: $white-color;
      border-radius: 8px;

      div {
        display: flex;
        justify-content: space-between;
      }
    }

    > :last-child {
      padding: 14px;
      display: flex;
      justify-content: space-between;
    }
  }
}

