.outer-container{
    height: 100%;
    width: 100%;
    background-color: #E5E5E5;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 24px;
    box-sizing: border-box;
}

.white-container {
  padding: 110px 190px;
  background: #ffffff;
  border: 1px solid #f21d86;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 48px;
  align-items: center;
}

@media screen and (max-width: 820px) {
  .white-container {
    padding: 116px 59px;
    gap: 40px;
  }
}
