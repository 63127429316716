.support-form {
  padding: 24px;
}
.support-list {
  @extend .add-product-form;
}
.query-list-title {
  padding: 4px 0 12px;
  font-weight: 400;
  font-size: $body;
}
.query-list {
  > .MuiPaper-root {
    color: #000;
    margin-bottom: 16px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
    &::before {
      display: none;
    }
    .MuiAccordionSummary-root{
      min-height: 40px;
      padding: 0 14px;
    }
    .MuiAccordionSummary-content {
      margin: 8px 0;
      .MuiTypography-root {
        font-size: $subTitle;
      }
    }
    .MuiAccordionSummary-expandIconWrapper {
      color: #000;
    }
    .MuiCollapse-root {
      background: #f9f9fa;
      .MuiAccordionDetails-root{
        padding: 8px 14px;
      }
      .MuiTypography-root {
        font-size: $placeholder !important;
      }
    }
    
  }
}

@media (max-width: 820px) {
  .support-form {
    padding-left: 4px;
  }
  .query-list-title {
    font-size: $placeholder;
  }
  .query-list {
    > .MuiPaper-root {
      margin-bottom: 12px;
      .MuiAccordionSummary-content {
        .MuiTypography-root {
          font-size: $placeholder;
        }
      }
      .MuiCollapse-root {
        .MuiTypography-root {
          font-size: $caption !important;
        }
      }
      
    }
  }
}
