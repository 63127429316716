.detail-value.details-instruction-container {
  word-break:break-all;
}
.subcategory-view {
  font-weight: 400;
}
.file-value {
  color: #000;
  text-decoration: none;
}

@media print {
  .invoice-table {
    display: none;
  }
  .file-container{
    display: none;
  }
  .print-table {
    .invoice-header {
      grid-template-columns: repeat(4, 1fr);
      :last-child{
        text-align: right
      }
    }
    .print-table-body {
      .invoice-row {
        grid-template-columns: repeat(4, 1fr);
        :last-child{
          text-align: right
        }
      }
    }
  }
}
