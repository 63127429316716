.account-page-container {
  margin: 0;
  .accounting-detail-block {
    max-width: 1112px;
    width: 100%;
    margin: 0 auto 16px;
  }
  .form-button-row {
    max-width: 1112px;
    width: 100%;
    margin: 48px auto 0;
    gap: 24px;
  }
}
.iframe-modal {
  height: 550px;
}
.credential-modal-body {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.organization-modal {
  .organization-container {
    width: 100%;
    height: 76px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    padding: 24px 12px 24px 26px;
    >span{
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
    }
  }
}
@media (max-width: 576px) {
  .account-page-container .accounting-detail-block {
    padding: 8px 14px;
  }
  .account-page-container .form-button-row {
    margin: 32px auto 0;
  }
  .iframe-modal {
    height: 500px;
  }
}
